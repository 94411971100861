import { useState, useEffect } from 'react';
import axios from 'axios';
import { ClipboardCheck } from 'lucide-react';
import WorkoutReview from './WorkoutReview';

export default function WorkoutAssignment() {
  const [selectedAssignment, setSelectedAssignment] = useState(null);
  const [clients, setClients] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [assignments, setAssignments] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [viewMode, setViewMode] = useState('pending');
  const API_URL = 'http://155.138.209.208:8000';

 // eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => {
    fetchAssignments();
    fetchClients();
    fetchTemplates();
  }, [viewMode]);

  const fetchAssignments = async () => {
    try {
      const response = await axios.get(`${API_URL}/assigned-workouts?status=${viewMode}`);
      setAssignments(response.data);
    } catch (error) {
      console.error('Error fetching assignments:', error);
    }
  };

  const fetchClients = async () => {
    try {
      const response = await axios.get(`${API_URL}/clients`);
      setClients(response.data);
    } catch (error) {
      console.error('Error fetching clients:', error);
    }
  };

  const fetchTemplates = async () => {
    try {
      const response = await axios.get(`${API_URL}/workout-templates`);
      setTemplates(response.data);
    } catch (error) {
      console.error('Error fetching templates:', error);
    }
  };

  const handleAssign = async (e) => {
    e.preventDefault();
    if (!selectedClient || !selectedTemplate) {
      alert('Please select both a client and a workout template');
      return;
    }

    try {
      await axios.post(`${API_URL}/assign-workout`, {
        client_id: selectedClient,
        template_id: selectedTemplate,
      });
      fetchAssignments();
      setShowAssignModal(false);
      setSelectedClient('');
      setSelectedTemplate(null);
    } catch (error) {
      console.error('Error assigning workout:', error);
      alert('Error assigning workout');
    }
  };

  const AssignModal = () => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg p-6 max-w-md w-full">
        <h3 className="text-lg font-semibold mb-4">Assign Workout</h3>
        <form onSubmit={handleAssign} className="space-y-4">
          <div>
            <label className="block text-sm font-medium mb-1">Client</label>
            <select
              value={selectedClient}
              onChange={(e) => setSelectedClient(e.target.value)}
              className="w-full border rounded p-2"
              required
            >
              <option value="">Select Client</option>
              {clients.map(client => (
                <option key={client.id} value={client.id}>
                  {client.first_name} {client.last_name}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium mb-1">Workout Template</label>
            <select
              value={selectedTemplate}
              onChange={(e) => setSelectedTemplate(e.target.value)}
              className="w-full border rounded p-2"
              required
            >
              <option value="">Select Template</option>
              {templates.map(template => (
                <option key={template.id} value={template.id}>
                  {template.name}
                </option>
              ))}
            </select>
          </div>

          <div className="flex justify-end space-x-2">
            <button
              type="button"
              onClick={() => setShowAssignModal(false)}
              className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              Assign
            </button>
          </div>
        </form>
      </div>
    </div>
  );

  return (
    <div className="space-y-6">
      {selectedAssignment ? (
        <WorkoutReview
          assignmentId={selectedAssignment}
          onBack={() => setSelectedAssignment(null)}
        />
      ) : (
        <>
          <div className="flex justify-between items-center">
            <h2 className="text-2xl font-bold">Workout Assignments</h2>
            <button
              onClick={() => setShowAssignModal(true)}
              className="flex items-center gap-2 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
              <ClipboardCheck size={20} />
              Assign Workout
            </button>
          </div>

          <div className="flex space-x-4 bg-white p-4 rounded-lg shadow">
            <button
              onClick={() => setViewMode('pending')}
              className={`px-4 py-2 rounded ${viewMode === 'pending' ? 'bg-blue-500 text-white' : 'bg-gray-100'}`}
            >
              Pending
            </button>
            <button
              onClick={() => setViewMode('completed')}
              className={`px-4 py-2 rounded ${viewMode === 'completed' ? 'bg-blue-500 text-white' : 'bg-gray-100'}`}
            >
              Completed
            </button>
            <button
              onClick={() => setViewMode('all')}
              className={`px-4 py-2 rounded ${viewMode === 'all' ? 'bg-blue-500 text-white' : 'bg-gray-100'}`}
            >
              All
            </button>
          </div>

          <div className="bg-white rounded-lg shadow overflow-hidden">
            <table className="min-w-full">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Client</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Workout</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Due Date</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Status</th>
                  <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase">Actions</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {assignments.map(assignment => (
                  <tr key={assignment.id} className="hover:bg-gray-50">
                    <td className="px-6 py-4">{assignment.client_name}</td>
                    <td className="px-6 py-4">{assignment.workout_name}</td>
                    <td className="px-6 py-4">
                      {new Date(assignment.due_date).toLocaleDateString()}
                    </td>
                    <td className="px-6 py-4">
                      <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                        assignment.status === 'completed' 
                          ? 'bg-green-100 text-green-800' 
                          : 'bg-yellow-100 text-yellow-800'
                      }`}>
                        {assignment.status}
                      </span>
                    </td>
                    <td className="px-6 py-4 text-right">
                      <button
                        onClick={() => setSelectedAssignment(assignment.id)}
                        className="text-blue-600 hover:text-blue-900"
                      >
                        View Details
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {showAssignModal && <AssignModal />}
        </>
      )}
    </div>
  );
}
