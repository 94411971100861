// /opt/fitness-frontend/src/components/ClientManagement.js

import { useState, useEffect } from 'react';
import axios from 'axios';
import { UserPlus, Edit, Trash2, Check, X, Search } from 'lucide-react';

export default function ClientManagement() {
  const [clients, setClients] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterStatus, setFilterStatus] = useState('all');
  const API_URL = 'http://155.138.209.208:8000';

  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    current_weight: '',
    goal_weight: '',
    height: '',
    fitness_goal: ''
  });

  useEffect(() => {
    fetchClients();
  }, [filterStatus]);

  const fetchClients = async () => {
    try {
      const response = await axios.get(`${API_URL}/clients${filterStatus !== 'all' ? `?status=${filterStatus}` : ''}`);
      setClients(response.data);
    } catch (error) {
      console.error('Error fetching clients:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (selectedClient) {
        await axios.put(`${API_URL}/clients/${selectedClient.id}`, formData);
      } else {
        await axios.post(`${API_URL}/clients`, formData);
      }
      fetchClients();
      setShowAddModal(false);
      setShowEditModal(false);
      resetForm();
    } catch (error) {
      alert('Error saving client: ' + error.message);
    }
  };

  const handleEdit = (client) => {
    setSelectedClient(client);
    setFormData({
      first_name: client.first_name,
      last_name: client.last_name,
      email: client.email,
      phone: client.phone || '',
      current_weight: client.current_weight || '',
      goal_weight: client.goal_weight || '',
      height: client.height || '',
      fitness_goal: client.fitness_goal || ''
    });
    setShowEditModal(true);
  };

  const handleStatusChange = async (clientId, newStatus) => {
    try {
      await axios.patch(`${API_URL}/clients/${clientId}/status`, { status: newStatus });
      fetchClients();
    } catch (error) {
      alert('Error updating client status: ' + error.message);
    }
  };

  const resetForm = () => {
    setFormData({
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      current_weight: '',
      goal_weight: '',
      height: '',
      fitness_goal: ''
    });
    setSelectedClient(null);
  };

  const ClientModal = ({ onClose, client }) => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg p-6 max-w-md w-full">
        <h3 className="text-lg font-semibold mb-4">
          {client ? 'Edit Client' : 'Add New Client'}
        </h3>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium mb-1">First Name</label>
              <input
                type="text"
                className="w-full border rounded p-2"
                value={formData.first_name}
                onChange={(e) => setFormData({...formData, first_name: e.target.value})}
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-1">Last Name</label>
              <input
                type="text"
                className="w-full border rounded p-2"
                value={formData.last_name}
                onChange={(e) => setFormData({...formData, last_name: e.target.value})}
                required
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium mb-1">Email</label>
            <input
              type="email"
              className="w-full border rounded p-2"
              value={formData.email}
              onChange={(e) => setFormData({...formData, email: e.target.value})}
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium mb-1">Phone</label>
            <input
              type="tel"
              className="w-full border rounded p-2"
              value={formData.phone}
              onChange={(e) => setFormData({...formData, phone: e.target.value})}
            />
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium mb-1">Current Weight (lbs)</label>
              <input
                type="number"
                step="0.1"
                className="w-full border rounded p-2"
                value={formData.current_weight}
                onChange={(e) => setFormData({...formData, current_weight: e.target.value})}
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-1">Goal Weight (lbs)</label>
              <input
                type="number"
                step="0.1"
                className="w-full border rounded p-2"
                value={formData.goal_weight}
                onChange={(e) => setFormData({...formData, goal_weight: e.target.value})}
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium mb-1">Height (inches)</label>
            <input
              type="number"
              step="0.1"
              className="w-full border rounded p-2"
              value={formData.height}
              onChange={(e) => setFormData({...formData, height: e.target.value})}
            />
          </div>

          <div>
            <label className="block text-sm font-medium mb-1">Fitness Goal</label>
            <select
              className="w-full border rounded p-2"
              value={formData.fitness_goal}
              onChange={(e) => setFormData({...formData, fitness_goal: e.target.value})}
            >
              <option value="">Select Goal</option>
              <option value="weight_loss">Weight Loss</option>
              <option value="muscle_gain">Muscle Gain</option>
              <option value="maintenance">Maintenance</option>
              <option value="strength">Strength</option>
            </select>
          </div>

          <div className="flex justify-end space-x-2">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              {client ? 'Update' : 'Add'} Client
            </button>
          </div>
        </form>
      </div>
    </div>
  );

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold">Client Management</h2>
        <button
          onClick={() => setShowAddModal(true)}
          className="flex items-center gap-2 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          <UserPlus size={20} />
          Add Client
        </button>
      </div>

      {/* Filters */}
      <div className="flex gap-4 bg-white p-4 rounded-lg shadow">
        <div className="flex-1">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
            <input
              type="text"
              placeholder="Search clients..."
              className="w-full pl-10 pr-4 py-2 border rounded"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
        <select
          className="border rounded px-4 py-2"
          value={filterStatus}
          onChange={(e) => setFilterStatus(e.target.value)}
        >
          <option value="all">All Clients</option>
          <option value="active">Active</option>
          <option value="inactive">Inactive</option>
        </select>
      </div>

      {/* Client List */}
      <div className="bg-white rounded-lg shadow overflow-hidden">
        <table className="min-w-full">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Client</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Contact</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Weights</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Goal</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
              <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {clients
              .filter(client => 
                client.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                client.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                client.email.toLowerCase().includes(searchTerm.toLowerCase())
              )
              .map(client => (
                <tr key={client.id} className="hover:bg-gray-50">
                  <td className="px-6 py-4">
                    <div className="font-medium">{client.first_name} {client.last_name}</div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="text-sm">{client.email}</div>
                    <div className="text-sm text-gray-500">{client.phone}</div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="text-sm">Current: {client.current_weight} lbs</div>
                    <div className="text-sm text-gray-500">Goal: {client.goal_weight} lbs</div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="text-sm">{client.fitness_goal}</div>
                  </td>
                  <td className="px-6 py-4">
                    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                      client.status === 'active' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                    }`}>
                      {client.status}
                    </span>
                  </td>
                  <td className="px-6 py-4 text-right space-x-2">
                    <button
                      onClick={() => handleEdit(client)}
                      className="text-blue-600 hover:text-blue-900"
                    >
                      <Edit size={16} />
                    </button>
                    <button
                      onClick={() => handleStatusChange(client.id, client.status === 'active' ? 'inactive' : 'active')}
                      className={`${
                        client.status === 'active' ? 'text-red-600 hover:text-red-900' : 'text-green-600 hover:text-green-900'
                      }`}
                    >
                      {client.status === 'active' ? <X size={16} /> : <Check size={16} />}
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      {/* Modals */}
      {showAddModal && <ClientModal onClose={() => setShowAddModal(false)} />}
      {showEditModal && <ClientModal onClose={() => setShowEditModal(false)} client={selectedClient} />}
    </div>
  );
}
