// /opt/fitness-frontend/src/components/WorkoutTemplates.js

import { useState, useEffect } from 'react';
import axios from 'axios';
import { Plus, Save, X, Copy, Edit } from 'lucide-react';

export default function WorkoutTemplates() {
  const [templates, setTemplates] = useState([]);
  const [exercises, setExercises] = useState([]);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const API_URL = 'http://155.138.209.208:8000';

  const [templateForm, setTemplateForm] = useState({
    name: '',
    description: '',
    exercises: []
  });

  useEffect(() => {
    fetchTemplates();
    fetchExercises();
  }, []);

  const fetchTemplates = async () => {
    try {
      const response = await axios.get(`${API_URL}/workout-templates`);
      setTemplates(response.data);
    } catch (error) {
      console.error('Error fetching templates:', error);
    }
  };

  const fetchExercises = async () => {
    try {
      const response = await axios.get(`${API_URL}/exercises`);
      setExercises(response.data.exercises);
    } catch (error) {
      console.error('Error fetching exercises:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (selectedTemplate) {
        await axios.put(`${API_URL}/workout-templates/${selectedTemplate.id}`, templateForm);
      } else {
        await axios.post(`${API_URL}/workout-templates`, templateForm);
      }
      fetchTemplates();
      setShowCreateModal(false);
      resetForm();
    } catch (error) {
      alert('Error saving template: ' + error.message);
    }
  };

  const handleExerciseAdd = (exercise) => {
    setTemplateForm(prev => ({
      ...prev,
      exercises: [...prev.exercises, {
        exercise_id: exercise.id,
        name: exercise.name,
        warmup_sets: exercise.default_warmup_sets,
        sets: exercise.default_sets,
        reps: exercise.default_reps,
        rpe_set1: exercise.default_rpe_set1,
        rpe_final: exercise.default_rpe_final,
        rest: exercise.default_rest,
        comments: ''
      }]
    }));
  };

  const handleExerciseRemove = (index) => {
    setTemplateForm(prev => ({
      ...prev,
      exercises: prev.exercises.filter((_, i) => i !== index)
    }));
  };

  const handleExerciseUpdate = (index, field, value) => {
    setTemplateForm(prev => {
      const newExercises = [...prev.exercises];
      newExercises[index] = {
        ...newExercises[index],
        [field]: value
      };
      return {
        ...prev,
        exercises: newExercises
      };
    });
  };

  const resetForm = () => {
    setTemplateForm({
      name: '',
      description: '',
      exercises: []
    });
    setSelectedTemplate(null);
  };

  const duplicateTemplate = async (template) => {
    setTemplateForm({
      ...template,
      name: `${template.name} (Copy)`,
    });
    setShowCreateModal(true);
  };

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold">Workout Templates</h2>
        <button
          onClick={() => setShowCreateModal(true)}
          className="flex items-center gap-2 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          <Plus size={20} />
          Create Template
        </button>
      </div>

      {/* Template List */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {templates.map(template => (
          <div key={template.id} className="bg-white rounded-lg shadow-md p-6">
            <div className="flex justify-between items-start mb-4">
              <h3 className="text-lg font-semibold">{template.name}</h3>
              <div className="flex space-x-2">
                <button
                  onClick={() => duplicateTemplate(template)}
                  className="text-blue-500 hover:text-blue-700"
                >
                  <Copy size={16} />
                </button>
                <button
                  onClick={() => {
                    setSelectedTemplate(template);
                    setTemplateForm(template);
                    setShowCreateModal(true);
                  }}
                  className="text-green-500 hover:text-green-700"
                >
                  <Edit size={16} />
                </button>
              </div>
            </div>
            <p className="text-gray-600 text-sm mb-4">{template.description}</p>
            <div className="space-y-2">
              {template.exercises.map((exercise, index) => (
                <div key={index} className="text-sm text-gray-700">
                  • {exercise.name} ({exercise.sets}×{exercise.reps})
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      {/* Create/Edit Modal */}
      {showCreateModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg p-6 max-w-4xl w-full max-h-[90vh] overflow-y-auto">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-semibold">
                {selectedTemplate ? 'Edit Template' : 'Create Template'}
              </h3>
              <button onClick={() => setShowCreateModal(false)}>
                <X size={20} />
              </button>
            </div>

            <form onSubmit={handleSubmit} className="space-y-6">
              {/* Template Details */}
              <div className="grid grid-cols-1 gap-4">
                <div>
                  <label className="block text-sm font-medium mb-1">Template Name</label>
                  <input
                    type="text"
                    className="w-full border rounded p-2"
                    value={templateForm.name}
                    onChange={(e) => setTemplateForm({...templateForm, name: e.target.value})}
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium mb-1">Description</label>
                  <textarea
                    className="w-full border rounded p-2"
                    value={templateForm.description}
                    onChange={(e) => setTemplateForm({...templateForm, description: e.target.value})}
                    rows={3}
                  />
                </div>
              </div>

              {/* Exercise Selection */}
              <div className="border-t pt-4">
                <h4 className="font-medium mb-2">Add Exercises</h4>
                <div className="flex gap-2 mb-4">
                  <input
                    type="text"
                    placeholder="Search exercises..."
                    className="flex-1 border rounded p-2"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                  {exercises
                    .filter(ex => ex.name.toLowerCase().includes(searchTerm.toLowerCase()))
                    .map(exercise => (
                      <div
                        key={exercise.id}
                        className="flex justify-between items-center p-2 bg-gray-50 rounded"
                      >
                        <span>{exercise.name}</span>
                        <button
                          type="button"
                          onClick={() => handleExerciseAdd(exercise)}
                          className="text-blue-500 hover:text-blue-700"
                        >
                          <Plus size={16} />
                        </button>
                      </div>
                    ))}
                </div>
              </div>

              {/* Selected Exercises */}
              <div className="space-y-4">
                <h4 className="font-medium">Template Exercises</h4>
                {templateForm.exercises.map((exercise, index) => (
                  <div key={index} className="border rounded p-4">
                    <div className="flex justify-between items-center mb-2">
                      <h5 className="font-medium">{exercise.name}</h5>
                      <button
                        type="button"
                        onClick={() => handleExerciseRemove(index)}
                        className="text-red-500 hover:text-red-700"
                      >
                        <X size={16} />
                      </button>
                    </div>
                    <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                      <div>
                        <label className="block text-sm">Warmup Sets</label>
                        <input
                          type="number"
                          className="w-full border rounded p-1"
                          value={exercise.warmup_sets || ''}
                          onChange={(e) => handleExerciseUpdate(index, 'warmup_sets', e.target.value)}
                        />
                      </div>
                      <div>
                        <label className="block text-sm">Sets</label>
                        <input
                          type="number"
                          className="w-full border rounded p-1"
                          value={exercise.sets || ''}
                          onChange={(e) => handleExerciseUpdate(index, 'sets', e.target.value)}
                          required
                        />
                      </div>
                      <div>
                        <label className="block text-sm">Reps</label>
                        <input
                          type="text"
                          className="w-full border rounded p-1"
                          value={exercise.reps || ''}
                          onChange={(e) => handleExerciseUpdate(index, 'reps', e.target.value)}
                          required
                        />
                      </div>
                      <div>
                        <label className="block text-sm">Rest (min)</label>
                        <input
                          type="number"
                          step="0.5"
                          className="w-full border rounded p-1"
                          value={exercise.rest || ''}
                          onChange={(e) => handleExerciseUpdate(index, 'rest', e.target.value)}
                        />
                      </div>
                      <div>
                        <label className="block text-sm">RPE Start</label>
                        <input
                          type="number"
                          className="w-full border rounded p-1"
                          value={exercise.rpe_set1 || ''}
                          onChange={(e) => handleExerciseUpdate(index, 'rpe_set1', e.target.value)}
                        />
                      </div>
                      <div>
                        <label className="block text-sm">RPE Final</label>
                        <input
                          type="number"
                          className="w-full border rounded p-1"
                          value={exercise.rpe_final || ''}
                          onChange={(e) => handleExerciseUpdate(index, 'rpe_final', e.target.value)}
                        />
                      </div>
                      <div className="col-span-2">
                        <label className="block text-sm">Comments</label>
                        <textarea
                          className="w-full border rounded p-1"
                          value={exercise.comments || ''}
                          onChange={(e) => handleExerciseUpdate(index, 'comments', e.target.value)}
                          rows={2}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              {/* Submit Button */}
              <div className="flex justify-end space-x-2">
                <button
                  type="button"
                  onClick={() => setShowCreateModal(false)}
                  className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                >
                  {selectedTemplate ? 'Update' : 'Create'} Template
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}
