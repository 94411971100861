// At the top of App.js

import { useState, useEffect } from 'react';
import { Camera, Award, Utensils, Brain, History } from 'lucide-react';
import axios from 'axios';
import WorkoutCreator from './components/WorkoutCreator';
import ClientManagement from './components/ClientManagement';
import WorkoutTemplates from './components/WorkoutTemplates';
import WorkoutAssignment from './components/WorkoutAssignment';

const LogWorkoutForm = ({ onClose, onSubmit }) => (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
    <div className="bg-white rounded-lg p-6 max-w-md w-full">
      <h3 className="text-lg font-semibold mb-4">Log Workout</h3>
      <form onSubmit={onSubmit} className="space-y-4">
        <div>
          <label className="block text-sm font-medium mb-1">Type</label>
          <input type="text" name="type" className="w-full rounded-lg border p-2" required />
        </div>
        <div>
          <label className="block text-sm font-medium mb-1">Duration (minutes)</label>
          <input type="number" name="duration" className="w-full rounded-lg border p-2" required />
        </div>
        <div>
          <label className="block text-sm font-medium mb-1">Calories Burned</label>
          <input type="number" name="calories" className="w-full rounded-lg border p-2" required />
        </div>
        <div className="flex justify-end space-x-2">
          <button type="button" onClick={onClose} className="px-4 py-2 rounded-lg bg-gray-200">Cancel</button>
          <button type="submit" className="px-4 py-2 rounded-lg bg-blue-500 text-white">Save</button>
        </div>
      </form>
    </div>
  </div>
);

const LogMealForm = ({ onClose, onSubmit }) => (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
    <div className="bg-white rounded-lg p-6 max-w-md w-full">
      <h3 className="text-lg font-semibold mb-4">Log Meal</h3>
      <form onSubmit={onSubmit} className="space-y-4">
        <div>
          <label className="block text-sm font-medium mb-1">Food Name</label>
          <input type="text" name="food" className="w-full rounded-lg border p-2" required />
        </div>
        <div>
          <label className="block text-sm font-medium mb-1">Calories</label>
          <input type="number" name="calories" className="w-full rounded-lg border p-2" required />
        </div>
        <div>
          <label className="block text-sm font-medium mb-1">Protein (g)</label>
          <input type="number" name="protein" className="w-full rounded-lg border p-2" required />
        </div>
        <div>
          <label className="block text-sm font-medium mb-1">Carbs (g)</label>
          <input type="number" name="carbs" className="w-full rounded-lg border p-2" required />
        </div>
        <div>
          <label className="block text-sm font-medium mb-1">Fats (g)</label>
          <input type="number" name="fats" className="w-full rounded-lg border p-2" required />
        </div>
        <div className="flex justify-end space-x-2">
          <button type="button" onClick={onClose} className="px-4 py-2 rounded-lg bg-gray-200">Cancel</button>
          <button type="submit" className="px-4 py-2 rounded-lg bg-blue-500 text-white">Save</button>
        </div>
      </form>
    </div>
  </div>
);

export default function FitnessApp() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [activeTab, setActiveTab] = useState('dashboard');
  const [showWorkoutForm, setShowWorkoutForm] = useState(false);
  const [showMealForm, setShowMealForm] = useState(false);
  const [showAIAdvice, setShowAIAdvice] = useState(false);
  const [workouts, setWorkouts] = useState([]);
  const [nutrition, setNutrition] = useState([]);
  const [aiAdvice, setAiAdvice] = useState({ workout: '', nutrition: '' });

useEffect(() => {
  if (isLoggedIn) {
    fetchWorkouts();
    fetchNutrition();
  }
}, [isLoggedIn]);

  const API_URL = 'http://155.138.209.208:8000';

  const kgToLbs = (kg) => (kg * 2.20462).toFixed(1);

  const fetchWorkouts = async () => {
    try {
      const response = await axios.get(`${API_URL}/workouts/`);
      setWorkouts(response.data);
    } catch (error) {
      console.error('Error fetching workouts:', error);
    }
  };

  const fetchNutrition = async () => {
    try {
      const response = await axios.get(`${API_URL}/nutrition/`);
      setNutrition(response.data);
    } catch (error) {
      console.error('Error fetching nutrition:', error);
    }
  };

  const handleWorkoutSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const workoutData = {
      type: formData.get('type'),
      duration: parseInt(formData.get('duration')),
      calories_burned: parseInt(formData.get('calories'))
    };

    try {
      await axios.post(`${API_URL}/workouts/`, workoutData);
      fetchWorkouts();
      setShowWorkoutForm(false);
    } catch (error) {
      console.error('Error logging workout:', error);
    }
  };

  const handleMealSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const nutritionData = {
      food_name: formData.get('food'),
      calories: parseInt(formData.get('calories')),
      protein: parseInt(formData.get('protein')),
      carbs: parseInt(formData.get('carbs')),
      fats: parseInt(formData.get('fats'))
    };

    try {
      await axios.post(`${API_URL}/nutrition/`, nutritionData);
      fetchNutrition();
      setShowMealForm(false);
    } catch (error) {
      console.error('Error logging meal:', error);
    }
  };

  const getAIAdvice = async () => {
    try {
      const [workoutResponse, nutritionResponse] = await Promise.all([
        axios.get(`${API_URL}/ai/workout-plan`),
        axios.get(`${API_URL}/ai/nutrition-plan`)
      ]);
      setAiAdvice({
        workout: workoutResponse.data.plan,
        nutrition: nutritionResponse.data.plan
      });
      setShowAIAdvice(true);
    } catch (error) {
      console.error('Error getting AI advice:', error);
    }
  };

  const renderDashboard = () => (
    <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4">
      <div className="rounded-lg bg-gradient-to-br from-blue-500 to-blue-600 p-6 shadow-lg text-white">
        <div className="mb-4 flex items-center">
          <Camera className="mr-2" />
          <h2 className="text-xl font-semibold">Progress</h2>
        </div>
        <p>Current Weight: {kgToLbs(75)} lbs</p>
        <p>Goal Weight: {kgToLbs(70)} lbs</p>
        <div className="mt-4 h-2 bg-blue-200 rounded-full">
          <div className="h-2 bg-white rounded-full" style={{ width: '60%' }}></div>
        </div>
      </div>
      
      <div className="rounded-lg bg-gradient-to-br from-green-500 to-green-600 p-6 shadow-lg text-white">
        <div className="mb-4 flex items-center">
          <Award className="mr-2" />
          <h2 className="text-xl font-semibold">Today's Workout</h2>
        </div>
        <p>Last: Upper Body</p>
        <p>Duration: 45 mins</p>
        <button 
          onClick={() => setShowWorkoutForm(true)} 
          className="mt-4 rounded bg-white px-4 py-2 text-green-600 font-semibold hover:bg-green-50"
        >
          Log Workout
        </button>
      </div>
      
      <div className="rounded-lg bg-gradient-to-br from-purple-500 to-purple-600 p-6 shadow-lg text-white">
        <div className="mb-4 flex items-center">
          <Utensils className="mr-2" />
          <h2 className="text-xl font-semibold">Today's Nutrition</h2>
        </div>
        <p>Calories: 1800 / 2000</p>
        <p>Protein: 140g / 150g</p>
        <button 
          onClick={() => setShowMealForm(true)}
          className="mt-4 rounded bg-white px-4 py-2 text-purple-600 font-semibold hover:bg-purple-50"
        >
          Log Meal
        </button>
      </div>
      
      <div className="rounded-lg bg-gradient-to-br from-orange-500 to-orange-600 p-6 shadow-lg text-white">
        <div className="mb-4 flex items-center">
          <Brain className="mr-2" />
          <h2 className="text-xl font-semibold">AI Coach</h2>
        </div>
        <p>Get personalized workout and nutrition advice</p>
        <button 
          onClick={getAIAdvice}
          className="mt-4 rounded bg-white px-4 py-2 text-orange-600 font-semibold hover:bg-orange-50"
        >
          Get Advice
        </button>
      </div>
    </div>
  );

  const renderHistory = () => (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      <div className="bg-white rounded-lg shadow-lg p-6">
        <h2 className="text-xl font-semibold mb-4 flex items-center">
          <History className="mr-2" />
          Workout History
        </h2>
        <div className="space-y-4">
          {workouts.map((workout, i) => (
            <div key={i} className="border-b pb-4">
              <div className="flex justify-between items-center">
                <div>
                  <p className="font-semibold">{workout.type}</p>
                  <p className="text-sm text-gray-600">{workout.date}</p>
                </div>
                <div className="text-right">
                  <p>{workout.duration} mins</p>
                  <p className="text-sm text-gray-600">{workout.calories_burned} cal</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="bg-white rounded-lg shadow-lg p-6">
        <h2 className="text-xl font-semibold mb-4 flex items-center">
          <Utensils className="mr-2" />
          Nutrition History
        </h2>
        <div className="space-y-4">
          {nutrition.map((meal, i) => (
            <div key={i} className="border-b pb-4">
              <div className="flex justify-between items-center">
                <div>
                  <p className="font-semibold">{meal.food_name}</p>
                  <p className="text-sm text-gray-600">{meal.date}</p>
                </div>
                <div className="text-right">
                  <p>{meal.calories} cal</p>
                  <p className="text-sm text-gray-600">
                    P: {meal.protein}g | C: {meal.carbs}g | F: {meal.fats}g
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <div className="min-h-screen bg-gray-100">
      {!isLoggedIn ? (
        <div className="flex min-h-screen items-center justify-center bg-gradient-to-br from-blue-500 to-purple-600">
          <div className="w-full max-w-md space-y-8 rounded-lg bg-white p-8 shadow-lg">
            <h2 className="text-center text-3xl font-bold text-gray-900">Fitness AI</h2>
            <form onSubmit={(e) => { e.preventDefault(); setIsLoggedIn(true); }} className="space-y-4">
              <input
                type="text"
                placeholder="Username"
                className="w-full rounded-lg border border-gray-300 p-3 focus:border-blue-500 focus:outline-none"
              />
              <input
                type="password"
                placeholder="Password"
                className="w-full rounded-lg border border-gray-300 p-3 focus:border-blue-500 focus:outline-none"
              />
              <button
                type="submit"
                className="w-full rounded-lg bg-gradient-to-r from-blue-500 to-blue-600 p-3 text-white hover:from-blue-600 hover:to-blue-700"
              >
                Login
              </button>
            </form>
          </div>
        </div>
      ) : (
        <div className="min-h-screen bg-gray-100">
          <nav className="bg-white shadow-lg">
            <div className="container mx-auto px-4">
              <div className="flex h-16 items-center justify-between">
                <h1 className="text-2xl font-bold text-gray-900">Fitness AI</h1>
                <div className="flex space-x-4">
                  <button
                    onClick={() => setActiveTab('dashboard')}
                    className={`px-4 py-2 rounded-lg ${
                      activeTab === 'dashboard' 
                        ? 'bg-blue-500 text-white' 
                        : 'text-gray-600 hover:bg-gray-100'
                    }`}
                  >
                    Dashboard
                  </button>
                  <button
                    onClick={() => setActiveTab('history')}
                    className={`px-4 py-2 rounded-lg ${
                      activeTab === 'history' 
                        ? 'bg-blue-500 text-white' 
                        : 'text-gray-600 hover:bg-gray-100'
                    }`}
                  >
                    History
                  </button>
	      <button
    onClick={() => setActiveTab('workouts')}
    className={`px-4 py-2 rounded-lg ${
      activeTab === 'workouts'
        ? 'bg-blue-500 text-white'
        : 'text-gray-600 hover:bg-gray-100'
    }`}
  >
    Workouts
  </button>
                  <button
                    onClick={() => setIsLoggedIn(false)}
                    className="rounded-lg bg-red-500 px-4 py-2 text-white hover:bg-red-600"
                  >
                    Logout
                  </button>
	      <button
  onClick={() => setActiveTab('clients')}
  className={`px-4 py-2 rounded-lg ${
    activeTab === 'clients'
      ? 'bg-blue-500 text-white'
      : 'text-gray-600 hover:bg-gray-100'
  }`}
>
  Clients
</button>
	      <button
  onClick={() => setActiveTab('templates')}
  className={`px-4 py-2 rounded-lg ${
    activeTab === 'templates'
      ? 'bg-blue-500 text-white'
      : 'text-gray-600 hover:bg-gray-100'
  }`}
>
  Templates
</button>
	      <button
  onClick={() => setActiveTab('assignments')}
  className={`px-4 py-2 rounded-lg ${
    activeTab === 'assignments'
      ? 'bg-blue-500 text-white'
      : 'text-gray-600 hover:bg-gray-100'
  }`}
>
  Assignments
</button>
                </div>
              </div>
            </div>
          </nav>

	<main className="container mx-auto px-4 py-8">
  	{activeTab === 'dashboard' && renderDashboard()}
  	{activeTab === 'history' && renderHistory()}
  	{activeTab === 'workouts' && <WorkoutCreator />}
	{activeTab === 'clients' && <ClientManagement />}
	{activeTab === 'templates' && <WorkoutTemplates />}
	{activeTab === 'assignments' && <WorkoutAssignment />}
	      </main>
          {showWorkoutForm && <LogWorkoutForm onClose={() => setShowWorkoutForm(false)} onSubmit={handleWorkoutSubmit} />}
          {showMealForm && <LogMealForm onClose={() => setShowMealForm(false)} onSubmit={handleMealSubmit} />}
          {showAIAdvice && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
              <div className="bg-white rounded-lg p-6 max-w-2xl w-full max-h-[80vh] overflow-y-auto">
                <h3 className="text-lg font-semibold mb-4">AI Coach Advice</h3>
                <div className="space-y-4">
                  <div>
                    <h4 className="font-medium">Workout Plan</h4>
                    <p className="whitespace-pre-wrap">{aiAdvice.workout}</p>
                  </div>
                  <div>
                    <h4 className="font-medium">Nutrition Plan</h4>
                    <p className="whitespace-pre-wrap">{aiAdvice.nutrition}</p>
                  </div>
                </div>
                <button
                  onClick={() => setShowAIAdvice(false)}
                  className="mt-4 px-4 py-2 rounded-lg bg-blue-500 text-white"
                >
                  Close
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
