// /opt/fitness-frontend/src/components/WorkoutReview.js

import { useState, useEffect } from 'react';
import axios from 'axios';
import { CheckCircle, AlertCircle, Clock, ArrowLeft } from 'lucide-react';

export default function WorkoutReview({ assignmentId, onBack }) {
  const [assignment, setAssignment] = useState(null);
  const [loading, setLoading] = useState(true);
  const [completions, setCompletions] = useState([]);
  const API_URL = 'http://155.138.209.208:8000';

  useEffect(() => {
    fetchAssignmentDetails();
  }, [assignmentId]);

  const fetchAssignmentDetails = async () => {
    try {
      const response = await axios.get(`${API_URL}/assigned-workouts/${assignmentId}`);
      setAssignment(response.data.assignment);
      setCompletions(response.data.exercises.map(ex => ({
        exercise_id: ex.exercise_id,
        sets_completed: ex.sets_completed || 0,
        actual_reps: ex.actual_reps || '',
        actual_weight: ex.actual_weight || '',
        comments: ex.comments || '',
        exercise_name: ex.exercise_name,
        prescribed_sets: ex.prescribed_sets,
        prescribed_reps: ex.prescribed_reps,
        rpe_set1: ex.rpe_set1,
        rpe_final: ex.rpe_final,
        rest: ex.rest
      })));
      setLoading(false);
    } catch (error) {
      console.error('Error fetching assignment details:', error);
      setLoading(false);
    }
  };

  const handleComplete = async () => {
    try {
      await axios.post(`${API_URL}/assigned-workouts/${assignmentId}/complete`, completions);
      alert('Workout review completed successfully!');
      onBack();
    } catch (error) {
      alert('Error completing workout review: ' + error.message);
    }
  };

  const updateCompletion = (index, field, value) => {
    const newCompletions = [...completions];
    newCompletions[index] = {
      ...newCompletions[index],
      [field]: value
    };
    setCompletions(newCompletions);
  };

  if (loading) {
    return <div className="flex items-center justify-center h-64">
      <Clock className="animate-spin h-8 w-8 text-blue-500" />
    </div>;
  }

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="flex items-center justify-between">
        <button
          onClick={onBack}
          className="flex items-center text-gray-600 hover:text-gray-900"
        >
          <ArrowLeft className="mr-2" size={20} />
          Back to Assignments
        </button>
        <button
          onClick={handleComplete}
          className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
        >
          Complete Review
        </button>
      </div>

      {/* Assignment Details */}
      <div className="bg-white rounded-lg shadow p-6">
        <div className="grid grid-cols-2 gap-4 mb-6">
          <div>
            <h2 className="text-2xl font-bold mb-2">{assignment.template_name}</h2>
            <p className="text-gray-600">Assigned to: {assignment.client_name}</p>
          </div>
          <div className="text-right">
            <p className="text-gray-600">Due Date: {new Date(assignment.due_date).toLocaleDateString()}</p>
            <span className={`inline-flex items-center px-2 py-1 rounded-full text-sm ${
              assignment.status === 'completed' 
                ? 'bg-green-100 text-green-800' 
                : 'bg-yellow-100 text-yellow-800'
            }`}>
              {assignment.status === 'completed' ? (
                <CheckCircle className="mr-1" size={16} />
              ) : (
                <AlertCircle className="mr-1" size={16} />
              )}
              {assignment.status}
            </span>
          </div>
        </div>

        {/* Exercises */}
        <div className="space-y-6">
          {completions.map((completion, index) => (
            <div key={index} className="border rounded-lg p-4">
              <h3 className="font-semibold mb-4">{completion.exercise_name}</h3>
              
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                <div>
                  <label className="block text-sm font-medium mb-1">
                    Sets Completed (Prescribed: {completion.prescribed_sets})
                  </label>
                  <input
                    type="number"
                    className="w-full border rounded p-2"
                    value={completion.sets_completed}
                    onChange={(e) => updateCompletion(index, 'sets_completed', parseInt(e.target.value))}
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium mb-1">
                    Actual Reps (Prescribed: {completion.prescribed_reps})
                  </label>
                  <input
                    type="text"
                    className="w-full border rounded p-2"
                    value={completion.actual_reps}
                    onChange={(e) => updateCompletion(index, 'actual_reps', e.target.value)}
                    placeholder="e.g., 8,8,7,6"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium mb-1">
                    Weight Used (lbs)
                  </label>
                  <input
                    type="number"
                    className="w-full border rounded p-2"
                    value={completion.actual_weight}
                    onChange={(e) => updateCompletion(index, 'actual_weight', parseFloat(e.target.value))}
                  />
                </div>

                <div className="md:col-span-2 lg:col-span-3">
                  <label className="block text-sm font-medium mb-1">
                    Comments/Notes
                  </label>
                  <textarea
                    className="w-full border rounded p-2"
                    value={completion.comments}
                    onChange={(e) => updateCompletion(index, 'comments', e.target.value)}
                    rows={2}
                    placeholder="Any notes about form, difficulty, or adjustments made"
                  />
                </div>
              </div>

              <div className="mt-4 text-sm text-gray-500">
                <p>Prescribed RPE: {completion.rpe_set1} - {completion.rpe_final}</p>
                <p>Rest Period: {completion.rest} minutes</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
