import { useState, useEffect } from 'react';
import axios from 'axios';

export default function WorkoutCreator() {
  const [exercises, setExercises] = useState([]);
  const [selectedExercises, setSelectedExercises] = useState([]);
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [workoutName, setWorkoutName] = useState('');
  const [templates, setTemplates] = useState([]);
  const [showTemplateModal, setShowTemplateModal] = useState(false);
  const API_URL = 'http://155.138.209.208:8000';

  useEffect(() => {
    fetchExercises();
    fetchClients();
    fetchTemplates();
  }, []);

  const fetchExercises = async () => {
    try {
      const response = await axios.get(`${API_URL}/exercises`);
      setExercises(response.data.exercises || []);
    } catch (error) {
      console.error('Error fetching exercises:', error);
    }
  };

  const fetchClients = async () => {
    try {
      const response = await axios.get(`${API_URL}/clients`);
      setClients(response.data.clients || []);
    } catch (error) {
      console.error('Error fetching clients:', error);
    }
  };

  const fetchTemplates = async () => {
    try {
      const response = await axios.get(`${API_URL}/workout-templates`);
      setTemplates(response.data.templates || []);
    } catch (error) {
      console.error('Error fetching templates:', error);
    }
  };

  const saveWorkout = async (asTemplate = false) => {
    if (!workoutName) {
      alert('Please enter a workout name');
      return;
    }

    if (!asTemplate && !selectedClient) {
      alert('Please select a client');
      return;
    }

    const workoutData = {
      name: workoutName,
      client_id: asTemplate ? null : selectedClient,
      is_template: asTemplate,
      exercises: selectedExercises.map(exercise => ({
        exercise_id: exercise.id,
        warmup_sets: exercise.warmup_sets,
        sets: exercise.sets,
        reps: exercise.reps,
        rpe_set1: exercise.rpe_set1,
        rpe_final: exercise.rpe_final,
        rest: exercise.rest,
        comments: exercise.comments
      }))
    };

    try {
      const endpoint = asTemplate ? 'workout-templates' : 'workouts';
      await axios.post(`${API_URL}/${endpoint}`, workoutData);
      alert(`Workout ${asTemplate ? 'template' : ''} saved successfully!`);
      if (!asTemplate) {
        setSelectedExercises([]);
        setWorkoutName('');
        setSelectedClient('');
      }
    } catch (error) {
      alert('Error saving workout: ' + error.message);
    }
  };

  return (
    <div className="space-y-6">
      <div className="bg-white p-6 rounded-lg shadow mb-6">
        <h2 className="text-2xl font-bold mb-4">Create Workout</h2>
        
        {/* Workout Details */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
          <div>
            <label className="block text-sm font-medium mb-1">Workout Name</label>
            <input
              type="text"
              className="w-full border rounded p-2"
              value={workoutName}
              onChange={(e) => setWorkoutName(e.target.value)}
              placeholder="Enter workout name"
            />
          </div>
          
          <div>
            <label className="block text-sm font-medium mb-1">Assign to Client</label>
            <select
              className="w-full border rounded p-2"
              value={selectedClient}
              onChange={(e) => setSelectedClient(e.target.value)}
            >
              <option value="">Select Client</option>
              {clients.map((client) => (
                <option key={client.id} value={client.id}>
                  {client.first_name} {client.last_name}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Template Selection */}
        <div className="mb-6">
          <button
            onClick={() => setShowTemplateModal(true)}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            Load from Template
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Exercise Library */}
        <div className="bg-white p-6 rounded-lg shadow">
          <h3 className="text-xl font-semibold mb-4">Exercise Library</h3>
          <div className="space-y-2 max-h-[600px] overflow-y-auto">
            {exercises.map((exercise) => (
              <div 
                key={exercise.id}
                className="flex justify-between items-center p-3 bg-gray-50 rounded hover:bg-gray-100"
              >
                <span>{exercise.name}</span>
                <button
                  onClick={() => setSelectedExercises([...selectedExercises, {
                    ...exercise,
                    sets: exercise.default_sets,
                    reps: exercise.default_reps,
                    warmup_sets: exercise.warmup_sets,
                    rpe_set1: exercise.default_rpe_set1,
                    rpe_final: exercise.default_rpe_final,
                    rest: exercise.default_rest
                  }])}
                  className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
                >
                  Add
                </button>
              </div>
            ))}
          </div>
        </div>

        {/* Workout Plan */}
        <div className="bg-white p-6 rounded-lg shadow">
          <h3 className="text-xl font-semibold mb-4">Workout Plan</h3>
          <div className="space-y-4">
            {selectedExercises.map((exercise, index) => (
              <div key={index} className="p-4 bg-gray-50 rounded">
                <div className="flex justify-between items-center mb-2">
                  <h4 className="font-medium">{exercise.name}</h4>
                  <button
                    onClick={() => {
                      const newExercises = [...selectedExercises];
                      newExercises.splice(index, 1);
                      setSelectedExercises(newExercises);
                    }}
                    className="text-red-500 hover:text-red-700"
                  >
                    Remove
                  </button>
                </div>
                
                <div className="grid grid-cols-2 gap-2">
                  {/* Exercise fields with labels */}
                  <div>
                    <label className="block text-sm font-medium">Warmup Sets</label>
                    <input 
                      type="number"
                      className="w-full border rounded p-2"
                      value={exercise.warmup_sets || ''}
                      onChange={(e) => {
                        const newExercises = [...selectedExercises];
                        newExercises[index] = {
                          ...exercise,
                          warmup_sets: parseInt(e.target.value)
                        };
                        setSelectedExercises(newExercises);
                      }}
                    />
                  </div>
                  
                  {/* Add similar fields for sets, reps, RPE, etc. */}
                  {/* ... other exercise fields ... */}
                </div>
              </div>
            ))}
            
            {selectedExercises.length > 0 && (
              <div className="flex space-x-4 mt-6">
                <button
                  onClick={() => saveWorkout(false)}
                  className="flex-1 bg-green-500 text-white py-2 rounded hover:bg-green-600"
                >
                  Assign to Client
                </button>
                <button
                  onClick={() => saveWorkout(true)}
                  className="flex-1 bg-blue-500 text-white py-2 rounded hover:bg-blue-600"
                >
                  Save as Template
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Template Modal */}
      {showTemplateModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg max-w-2xl w-full">
            <h3 className="text-xl font-bold mb-4">Load Template</h3>
            <div className="max-h-[400px] overflow-y-auto">
              {templates.map((template) => (
                <div 
                  key={template.id}
                  className="p-4 border-b hover:bg-gray-50 cursor-pointer"
                  onClick={() => {
                    setSelectedExercises(template.exercises);
                    setShowTemplateModal(false);
                  }}
                >
                  <h4 className="font-medium">{template.name}</h4>
                  <p className="text-sm text-gray-600">
                    {template.exercises.length} exercises
                  </p>
                </div>
              ))}
            </div>
            <button
              onClick={() => setShowTemplateModal(false)}
              className="mt-4 bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
